import React, { Component } from 'react';
import * as lang from './../../lang.js';
import * as ajax from './../../ajax.js';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './../../components/PagingToolbar';
import CellRendererBoolean from './../../components/CellRendererBoolean';

import {Button} from 'react-bootstrap';

import InsertForm from './MunkalapInsertForm.js';
import UpdateForm from './MunkalapUpdateForm.js';
import ViewForm from './MunkalapViewForm.js';

import Dialog from './../../components/Dialog.js';
import ActionCell from './../../components/ActionCellMunkalap';
import GlobalSearch from './../../components/GlobalSearch';
import DriveLinkTableButton from './../../components/DriveLinkTableButton';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class MunkalapTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'action_cell',
            'drive_link',
            'munkalap_id',
            'munkalap_sorszam',
            'munkalap_ugyfel',
            'gyarto_szemelyek',
            'arajanlat_azonosito',
            'projektvezeto_id',
            'tervezo_id',

            'munkalap_datumok_rogzitve',
            'munkalap_datum',
            'munkalap_datum_festes',
            'munkalap_datum_festes_kesz',
            'munkalap_leiras',
            'munkalap_szinkod',
            'munkalap_megjegyzes',
            'munkalap_megnevezes',

            'munkalap_festest_igenyel',
            'munkalap_festek_megrendelve',
            'munkalap_statusz'
        ];
        this.columnStateHandler = ajax.columnStateHandler(columnNames, "Munkalap", "munkalap");

        this.state = {
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 50,
                showingRowFrom: 0,
                showingRowTo: 50,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();

        this.globalSearch = {"name": "globalSearch", "operator": "contains", "type": "string", "value": ''};

        this.gridRef = null;

        this.filterInit = true;
        this.saveFilter = false;
        this.enableFiltering = true;

        this.filtersLoaded = false;

        this.munkalap_projektvezeto_idData = [];
        this.munkalap_tervezo_idData = [];

        this.filterInit = true; // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE
        this.saveFilter = false;  // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE

    }

    componentDidMount() {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Munkalap/filtersData.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;


                        self.munkalap_projektvezeto_idData = data.munkalap_projektvezeto_id;
                        self.munkalap_tervezo_idData = data.munkalap_tervezo_id;

                        self.filtersLoaded = true;
                        self.forceUpdate();

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.openedForm != nextState.openedForm) {
            return true;
        }
        if (nextProps.theme != nextState.theme) {
            return true;
        }
        return false; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: lang.getTitle("ROW_SELECTION_NEED")});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = lang.getTitle("DELETE_CONFIRMATION");
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    getIdFromParam = function () {
        var queryString = window.location.search;
        var urlParams = new URLSearchParams(queryString);
        var id = urlParams.get('id');
        var filterParam = {"name": "munkalap_ajanlat_id", "operator": "eq", "type": "number", "value": id};
        return filterParam;
    }

    hasExitsingFilter = function (currentFilters, newFilter) {

        for (var i = 0; i < currentFilters.length; i++) {
            var currentFilter = currentFilters[i];
            if (currentFilter.name == newFilter.name) {
                currentFilter.value = newFilter.value;
                return true;
            }
        }
        return false;
    }

    globalSearchHandler = function (filterValue) {

        var hasExistingFilter = this.hasExitsingFilter(filterValue, this.globalSearch);
        if (hasExistingFilter == false) {
            filterValue.push(this.globalSearch);
        }
        return filterValue;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){

        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE
        if (this.saveFilter == true) {
            this.columnStateHandler.saveFilter(filterValue);
            this.columnStateHandler.saveSortInfo(sortInfo);
            this.columnStateHandler.saveLimit(limit);
            this.saveFilter = false;
        }
        if (this.filterInit == true) {
            sortInfo = this.columnStateHandler.loadSortInfo();
            ajax.setSortInfo(this.gridRef, sortInfo);
            limit = this.columnStateHandler.loadLimit();
            this.state.paging.pageRowCount = limit;
            this.state.paging.showingRowTo = limit;
            ajax.setLimitInfo(this.gridRef, limit);

        }
        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE



        var newFilter = this.getIdFromParam();
        var munkalap_ajanlat_id = newFilter.value;
        if (typeof munkalap_ajanlat_id != 'undefined' && munkalap_ajanlat_id != null && munkalap_ajanlat_id != '') {
            var hasExitsingFilter = this.hasExitsingFilter(filterValue, newFilter);
            if (hasExitsingFilter == false) {
                filterValue.push(newFilter);
            }

        }

        filterValue = this.globalSearchHandler(filterValue);

        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);
        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/Munkalap/munkalapTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE
                        if (this.filterInit == true) {
                            var storedFilter = this.columnStateHandler.loadFilter();
                            console.log("storedFilter", storedFilter);
                            ajax.setStoredFilters(this.gridRef, storedFilter);
                            this.filterInit = false;
                        }
                        // FILTER ÁLLAPOTOK MENTÉSE ÉS BETÖTÉSE        

                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;


                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Munkalap/munkalapFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({munkalap_id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            window.open(ajax.getUrlBase() + 'modules/Drive/driveHandler.php?state=MT_' + this.state.selectedRow, '_blank');
                        } else {
                            alert(response.fail);
                        }
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {
        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.munkalap_id == 'undefined') {
            return false;
        }

        if (rowProps.data.munkalap_id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.munkalap_id});
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.columnStateHandler.storColumnVisibility(column.name, visible);
        this.forceUpdate();

    }

    setGlobalSearch = function (val) {
        this.globalSearch.value = val;
        this.forceUpdate();
    }

    setGridRef = function (val) {
        this.gridRef = val;
    }

    render() {
        var self = this;
        if (this.filtersLoaded == false) {
            return "";
        }

        const statuszok = {
            1: 'Rögzítve',
            2: 'Kiírva',
            3: 'Gyártásra adva',
            4: 'Festésre adva',
            5: 'Festőműhely átvette',
            6: 'Összeszerelésre kész',
            7: 'Szállításra kész',
            8: 'Kiszállítva'
        }


        var isViewForm = this.isViewForm();
        var columns = [
            {
                name: 'action_cell', header: '', minWidth: 210,
                render: ({ value, data }) => {
                    return <ActionCell 
                        stateReference ={this.state}
                        row_id={data.munkalap_id}   
                        setView={this.setView.bind(this)}
                        isViewForm ={isViewForm}
                        theme={this.props.theme}
                        />
                },
                sortable: false
            }
            , {
                name: 'drive_link', header: 'Link', maxWidth: 60,
                render: ({ value, data }) => {
                    return <DriveLinkTableButton
                        value={data.munkalap_driveLink}
                        />
                },
                sortable: false
            }
            , {name: 'munkalap_id', sortable: true, header: lang.getTitle('munkalap_id'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_id', 150), type: 'number', filterEditor: NumberFilter, defaultVisible: false}
            , {name: 'munkalap_sorszam', sortable: true, header: lang.getTitle('munkalap_sorszam'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_sorszam', 150), defaultVisible: true}
            , {name: 'munkalap_ugyfel', sortable: true, header: lang.getTitle('munkalap_ugyfel'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_ugyfel', 150), defaultVisible: true}
            , {name: 'gyarto_szemelyek', sortable: true, header: lang.getTitle('gyarto_szemelyek'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('gyarto_szemelyek', 150), defaultVisible: true}
            , {name: 'arajanlat_azonosito', sortable: true, header: lang.getTitle('arajanlat_azonosito'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('arajanlat_azonosito', 150), defaultVisible: true}
            , {
                name: 'projektvezeto_id',
                sortable: true,
                header: lang.getTitle('Projektvezető'),
                defaultWidth: this.columnStateHandler.getColumnWidth('projektvezeto_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.munkalap_projektvezeto_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.munkalap_projektvezeto_idData, value)}</div>
                }
            }
            , {
                name: 'tervezo_id',
                sortable: true,
                header: lang.getTitle('Tervező'),
                defaultWidth: this.columnStateHandler.getColumnWidth('tervezo_id', 150),
                defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: this.munkalap_tervezo_idData
                },
                render: ({ value }) => {
                    return <div>{ajax.getValueById(this.munkalap_tervezo_idData, value)}</div>
                }
            }
            , {name: 'munkalap_datum', sortable: true, header: lang.getTitle('Státusz dátum'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_datum', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'munkalap_datumok_rogzitve', sortable: true, header: lang.getTitle('munkalap_datumok_rogzitve'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_datumok_rogzitve', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'munkalap_datum_festes', sortable: true, header: lang.getTitle('munkalap_datum_festes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_datum_festes', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'munkalap_datum_festes_kesz', sortable: true, header: lang.getTitle('munkalap_datum_festes_kesz'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_datum_festes_kesz', 150), type: 'date', filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    // for range and notinrange operators, the index is 1 for the after field
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: true,
                        highlightWeekends: true,
                        placeholder: index == 1 ? 'Előtte...' : 'Utána...'
                }
                },
                render: ({ value, cellProps }) => {
                    if (typeof value == 'undefined' || value == null || value == '') {
                        return "";
                    }
                    return moment(value).format('YYYY-MM-DD')
                }
                , defaultVisible: true}
            , {name: 'munkalap_leiras', sortable: true, header: lang.getTitle('munkalap_leiras'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_leiras', 150), defaultVisible: true}
            , {name: 'munkalap_szinkod', sortable: true, header: lang.getTitle('munkalap_szinkod'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_szinkod', 150), defaultVisible: true}
            , {name: 'munkalap_megjegyzes', sortable: true, header: lang.getTitle('munkalap_megjegyzes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_megjegyzes', 150), defaultVisible: true}
            , {name: 'munkalap_megnevezes', sortable: true, header: lang.getTitle('munkalap_megnevezes'), filterDelay: 1000, defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_megnevezes', 150), defaultVisible: true}

            , {name: 'munkalap_festest_igenyel', sortable: true, header: lang.getTitle('munkalap_festest_igenyel'), defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_festest_igenyel', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }
            }
            , {name: 'munkalap_festek_megrendelve', sortable: true, header: lang.getTitle('munkalap_festek_megrendelve'), defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_festek_megrendelve', 150), defaultVisible: true,
                filterEditor: BoolFilter,
                render: ({ value, cellProps }) => {
                    return <CellRendererBoolean selected={value} theme={this.props.theme}/>
                }
            }
            , {name: 'munkalap_statusz', sortable: true, header: lang.getTitle('munkalap_statusz'), defaultWidth: this.columnStateHandler.getColumnWidth('munkalap_statusz', 150), defaultVisible: true,
                operator: 'inlist',
                type: 'select',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: [1, 2, 3, 4, 5, 6, 7, 8].map(c => {
                        return {id: c, label: statuszok[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{statuszok[value]}</div>
                }
            }
        ];

        const filters = [
            {name: 'munkalap_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'munkalap_sorszam', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_ugyfel', operator: 'contains', type: 'string', value: ''}
            , {name: 'gyarto_szemelyek', operator: 'contains', type: 'string', value: ''}
            , {name: 'arajanlat_azonosito', operator: 'contains', type: 'string', value: ''}
            , {name: 'projektvezeto_id', operator: 'inlist', type: 'select', value: []}
            , {name: 'tervezo_id', operator: 'inlist', type: 'select', value: []}
            , {name: 'munkalap_datum', operator: 'eq', type: 'date', value: ''}
            , {name: 'munkalap_datumok_rogzitve', operator: 'eq', type: 'date', value: ''}
            , {name: 'munkalap_datum_festes', operator: 'eq', type: 'date', value: ''}
            , {name: 'munkalap_datum_festes_kesz', operator: 'eq', type: 'date', value: ''}
            , {name: 'munkalap_leiras', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_szinkod', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_megjegyzes', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_megnevezes', operator: 'contains', type: 'string', value: ''}
            , {name: 'munkalap_festest_igenyel', operator: 'eq', type: 'boolean', value: this.state.munkalap_festest_igenyel}
            , {name: 'munkalap_festek_megrendelve', operator: 'eq', type: 'boolean', value: this.state.munkalap_festek_megrendelve}
            , {name: 'munkalap_statusz', operator: 'inlist', type: 'select', value: []}


        ]

        columns = this.columnStateHandler.setColumnsVisibility(columns);

        var openedForm = "";
        if (this.state.openedForm == this.FORM_VIEW) {
            openedForm = <ViewForm theme={this.props.theme} key={'munkalap_KEY_VIEW'} formType={this.FORM_VIEW} additionalParams={[{munkalapDokumentumok_munkalap_id: this.state.selectedRow}]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
        }

        if (this.state.openedForm == this.FORM_INSERT) {
            openedForm = <InsertForm  
                theme={this.props.theme} 
                key={'munkalap_KEY_INSERT'} 
                formType={this.FORM_INSERT} 
                additionalParams={[{munkalapDokumentumok_munkalap_id: this.state.selectedRow}]} 
                closeForm={this.closeForm.bind(this)} 
                arajanlat ={this.getIdFromParam()}
                />
        }

        if (this.state.openedForm == this.FORM_UPDATE) {

            var hasSelectedRow = this.hasSelectedRow();
            if (hasSelectedRow == true) {
                openedForm = <UpdateForm theme={this.props.theme} key={'munkalap_KEY_UPDATE'} formType={this.FORM_UPDATE} additionalParams={[{munkalapDokumentumok_munkalap_id: this.state.selectedRow}]} id={this.state.selectedRow} closeForm={this.closeForm.bind(this)} />
            } else {
                console.log("Nincs kiválasztott sor");
                // Alert window
            }
        }

        if (this.state.openedForm == this.FORM_DELETE) {
            openedForm = <Dialog 
                type={this.DIALOG_CONFIRMATION} 
                dialogText={this.state.dialogText} 
                callback={this.delete.bind(this)}
                cancel={() => this.setView({target: {id: this.NONE}})}
            
                theme={this.props.theme}
                />;
        }
        if (this.state.openedForm == this.DIALOG_ALERT) {
            openedForm = <Dialog 
                type={this.DIALOG_ALERT} 
                dialogText={this.state.dialogText} 
                callback={() => this.setView({target: {id: this.NONE}})}
                theme={this.props.theme}
                />;
        }


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var buttons = [];

        var viewButton = <div  key={'munkalap_KEY_VIEW_BUTTON'} >&nbsp;<Button className={'btn-sm'} id={this.FORM_VIEW} onClick={this.setView.bind(this)} variant="info">{lang.getTitle('View')}</Button></div>;
        var insertButton = <div key={'munkalap_KEY_INSERT_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_INSERT} onClick={this.setView.bind(this)} variant="success">{lang.getTitle('Insert')}</Button></div>;
        var updateButton = <div key={'munkalap_KEY_UPDATE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_UPDATE} onClick={this.setView.bind(this)} variant="warning">{lang.getTitle('Update')}</Button></div>;
        var deleteButton = <div key={'munkalap_KEY_DELETE_BUTTON'}>&nbsp;<Button className={'btn-sm'} id={this.FORM_DELETE} onClick={this.setView.bind(this)} variant="danger">{lang.getTitle('Delete')}</Button></div>;
        var saveFilter = <div key={'munkalap_KEY_SAVE_FILTER_BUTTON'}>&nbsp;
            <Button className={'btn-sm'} id={"munkalap_SAVE_FILTER"} 
                    onClick={() => {
                                    this.saveFilter = true;
                                    this.reload();
                                }} variant="outline-info">{lang.getTitle('Szűrések mentése')}
            </Button>
        </div>;



        var isViewForm = this.isViewForm();

        if (isViewForm) {
            buttons = [viewButton];
        } else {
            buttons = [viewButton, insertButton, updateButton, deleteButton, saveFilter];
        }
        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        return (
                <div id="MunkalapTable">
                    <div style={{display: "flex"}}>
                        {buttons}
                        <GlobalSearch 
                            theme={this.props.theme} 
                            globalSearch={this.setGlobalSearch.bind(this)} />
                    </div>
                    <ReactDataGrid 
                        onReady={this.setGridRef.bind(this)}
                
                        idProperty="munkalap_id" 
                        i18n={ajax.getI18n()}
                        columns={this.columnStateHandler.myColumnsReorder(columns)}
                        dataSource={this.dataLoader.bind(this)}
                        defaultFilterValue={filters}
                        enableSelection={true}
                
                        onRowClick={this.onRowClick.bind(this)}
                        onSelectionChange={this.onSelectionChange.bind(this)}
                        theme={theme}
                        style={{height: window.innerHeight - 180, marginTop: 10}}    
                
                        defaultLimit={50}
                        pagination={true}
                        renderPaginationToolbar={this.renderPaginationToolbar}
                        skip={this.state.skip}
                        limit={this.state.paging.pageRowCount}
                
                        onColumnResize={this.columnStateHandler.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.columnStateHandler.onColumnOrderChange.bind(this)}
                        onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                
                        />
                    {openedForm}            
                   
                </div>
                );
    }
}

export default MunkalapTable;
