import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import * as lang from './../../lang.js';

import printWhite  from './../../img/white/print_white_24dp.svg';
import printBlack  from './../../img/black/print_black_24dp.svg';
import googleDriveIcon  from './../../img/google-drive-social-media-svgrepo-com.svg';

import PopUpWindow from './../../components/PopUpWindow';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';

import ArajanlatTetelContent from './../../modules/ArajanlatTetel/ArajanlatTetelContent.js';
import MTOArajanlatTetelContent from './../../modules/MTOArajanlatTetel/MTOArajanlatTetelContent.js';
import TextInsertField from './../../components/TextInsertField';
import ComboBoxInsertField from './../../components/ComboBoxInsertField';
import CheckBoxInsertField from './../../components/CheckBoxInsertField';
import TextAreaInsertField from './../../components/TextAreaInsertField';
import DateInsertField from './../../components/DateInsertField';
import NumberInsertField from './../../components/NumberInsertField';
import TextFormatter from './../../components/TextFormatter';
import ArjanlatUgyfelCimCombo from './../../componentsSpecial/ArajanlatUgyfelCimCombo';
import ArajanlatSzallitasiCimCombo from './../../componentsSpecial/ArajanlatSzallitasiCimCombo';
import AjanlatUgyfelComboHozzaadassal from './../../componentsSpecial/AjanlatUgyfelComboHozzaadassal';
import ArajanlatKalkulator from './ArajanlatKalkulator';
import ArajanlatVerzioCombo from './../../componentsSpecial/ArajanlatVerzioCombo';



import RemoteDataDisplayField from './../../components/RemoteDataDisplayField';


import printWithoutMoney  from './../../img/white/insert_drive_file_white_24dp.svg';
import printWithMoney  from './../../img/white/request_quote_white_24dp.svg';



class ArajanlatUpdate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arajanlat_id: ''
            , arajanlat_ugyfel_id: ''
            , arajanlat_monogram: ''
            , arajanlat_statusz_kod: ''
            , arajanlat_azonosito: ''
            , arajanlat_verzio: ''
            , arajanlat_kapcsolattarto_id: ''
            , arajanlat_projekt_vezeto_id: ''
            , arajanlat_projekt_vezeto_ajanlatra: ''
            , arajanlat_tervezo_ajanlatra: ''
            , arajanlat_tervezo_id: ''

            , arajanlat_ugyfel_cim_id: ''
            , arajanlat_szallitasi_cim_id: ''
            , arajanlat_targy: ''
            , arajanlat_leiras: ''
            , arajanlat_arengedmeny: '0'
            , arajanlat_datum: ''
            , arajanlat_datum_ervenyes: ''
            , arajanlat_hatarido: ''
            , arajanlat_garancia: ''
            , arajanlat_muszaki_leirasok: ''
            , arajanlat_megjegyzes: ''
            , arajanlat_nem_tartalmazza: ''
            , arajanlat_ajanlat_keszito_id: ''
            , arajanlat_tulajdonos_id: ''
            , arajanlat_statusz: ''
            , arajanlat_hivatkozas_url: ''
            , arajanlat_szallitasi_mod_id: ''
            , arajanlat_fizetesi_feltetelek: ''
            , arajanlat_szulo_id: ''
            , arajanlat_legutolso: ''
            , ArajanlatTetel: []
            , MTOArajanlatTetel: []
            , saveAsNewVariant: false
            , veglegesitve: false
            , arajanlat_promon: false

        }


        this.references = [];

        this.references['arajanlat_ugyfel_id'] = React.createRef();
        this.references['arajanlat_monogram'] = React.createRef();
        this.references['arajanlat_statusz_kod'] = React.createRef();
        this.references['arajanlat_azonosito'] = React.createRef();
        this.references['arajanlat_verzio'] = React.createRef();

        this.references['arajanlat_projekt_vezeto_id'] = React.createRef();
        this.references['arajanlat_projekt_vezeto_ajanlatra'] = React.createRef();
        this.references['arajanlat_tervezo_ajanlatra'] = React.createRef();
        this.references['arajanlat_tervezo_id'] = React.createRef();

        this.references['arajanlat_ugyfel_cim_id'] = React.createRef();
        this.references['arajanlat_szallitasi_cim_id'] = React.createRef();
        this.references['arajanlat_targy'] = React.createRef();
        this.references['arajanlat_leiras'] = React.createRef();
        this.references['arajanlat_arengedmeny'] = React.createRef();
        this.references['arajanlat_datum'] = React.createRef();
        this.references['arajanlat_datum_ervenyes'] = React.createRef();
        this.references['arajanlat_hatarido'] = React.createRef();
        this.references['arajanlat_garancia'] = React.createRef();
        this.references['arajanlat_muszaki_leirasok'] = React.createRef();
        this.references['arajanlat_megjegyzes'] = React.createRef();
        this.references['arajanlat_nem_tartalmazza'] = React.createRef();
        this.references['arajanlat_ajanlat_keszito_id'] = React.createRef();
        this.references['arajanlat_tulajdonos_id'] = React.createRef();
        this.references['arajanlat_statusz'] = React.createRef();
        this.references['arajanlat_hivatkozas_url'] = React.createRef();
        this.references['arajanlat_szallitasi_mod_id'] = React.createRef();
        this.references['arajanlat_fizetesi_feltetelek'] = React.createRef();
        this.references['arajanlat_szulo_id'] = React.createRef();
        this.references['arajanlat_legutolso'] = React.createRef();
        this.references['arajanlat_MTO'] = React.createRef();
        this.references['arajanlat_MTO'] = React.createRef();
        this.references['aktualizalasMegjegyzes'] = React.createRef();
        this.references['arajanlat_promon'] = React.createRef();


        this.validators = [];
        this.validators['arajanlat_id'] = {fieldName: 'arajanlat_id', dataType: 'INT', value: this.state.arajanlat_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_ugyfel_id'] = {fieldName: 'arajanlat_ugyfel_id', dataType: 'INT', value: this.state.arajanlat_ugyfel_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_monogram'] = {fieldName: 'arajanlat_monogram', dataType: 'VARCHAR', value: this.state.arajanlat_monogram, mandatory: false, min: null, max: null};
        this.validators['arajanlat_statusz_kod'] = {fieldName: 'arajanlat_statusz_kod', dataType: 'VARCHAR', value: this.state.arajanlat_statusz_kod, mandatory: false, min: null, max: null};

        this.validators['arajanlat_verzio'] = {fieldName: 'arajanlat_verzio', dataType: 'INT', value: this.state.arajanlat_verzio, mandatory: false, min: null, max: null};

        this.validators['arajanlat_projekt_vezeto_id'] = {fieldName: 'arajanlat_projekt_vezeto_id', dataType: 'INT', value: this.state.arajanlat_projekt_vezeto_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_projekt_vezeto_ajanlatra'] = {fieldName: 'arajanlat_projekt_vezeto_ajanlatra', dataType: 'TINYINT', value: this.state.arajanlat_projekt_vezeto_ajanlatra, mandatory: false, min: null, max: null};
        this.validators['arajanlat_tervezo_ajanlatra'] = {fieldName: 'arajanlat_tervezo_ajanlatra', dataType: 'TINYINT', value: this.state.arajanlat_tervezo_ajanlatra, mandatory: false, min: null, max: null};
        this.validators['arajanlat_tervezo_id'] = {fieldName: 'arajanlat_tervezo_id', dataType: 'INT', value: this.state.arajanlat_tervezo_id, mandatory: false, min: null, max: null};

        this.validators['arajanlat_ugyfel_cim_id'] = {fieldName: 'arajanlat_ugyfel_cim_id', dataType: 'INT', value: this.state.arajanlat_ugyfel_cim_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_szallitasi_cim_id'] = {fieldName: 'arajanlat_szallitasi_cim_id', dataType: 'INT', value: this.state.arajanlat_szallitasi_cim_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_targy'] = {fieldName: 'arajanlat_targy', dataType: 'VARCHAR', value: this.state.arajanlat_targy, mandatory: false, min: null, max: null};
        this.validators['arajanlat_leiras'] = {fieldName: 'arajanlat_leiras', dataType: 'VARCHAR', value: this.state.arajanlat_leiras, mandatory: false, min: null, max: null};
        this.validators['arajanlat_arengedmeny'] = {fieldName: 'arajanlat_arengedmeny', dataType: 'VARCHAR', value: this.state.arajanlat_arengedmeny, mandatory: false, min: null, max: null};
        this.validators['arajanlat_datum'] = {fieldName: 'arajanlat_datum', dataType: 'DATE', value: this.state.arajanlat_datum, mandatory: false, min: null, max: null};
        this.validators['arajanlat_datum_ervenyes'] = {fieldName: 'arajanlat_datum_ervenyes', dataType: 'DATE', value: this.state.arajanlat_datum_ervenyes, mandatory: false, min: null, max: null};
        this.validators['arajanlat_hatarido'] = {fieldName: 'arajanlat_hatarido', dataType: 'VARCHAR', value: this.state.arajanlat_hatarido, mandatory: false, min: null, max: null};
        this.validators['arajanlat_garancia'] = {fieldName: 'arajanlat_garancia', dataType: 'VARCHAR', value: this.state.arajanlat_garancia, mandatory: false, min: null, max: null};
        this.validators['arajanlat_muszaki_leirasok'] = {fieldName: 'arajanlat_muszaki_leirasok', dataType: 'VARCHAR', value: this.state.arajanlat_muszaki_leirasok, mandatory: false, min: null, max: null};
        this.validators['arajanlat_megjegyzes'] = {fieldName: 'arajanlat_megjegyzes', dataType: 'VARCHAR', value: this.state.arajanlat_megjegyzes, mandatory: false, min: null, max: null};
        this.validators['arajanlat_nem_tartalmazza'] = {fieldName: 'arajanlat_nem_tartalmazza', dataType: 'VARCHAR', value: this.state.arajanlat_nem_tartalmazza, mandatory: false, min: null, max: null};
        this.validators['arajanlat_ajanlat_keszito_id'] = {fieldName: 'arajanlat_ajanlat_keszito_id', dataType: 'INT', value: this.state.arajanlat_ajanlat_keszito_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_tulajdonos_id'] = {fieldName: 'arajanlat_tulajdonos_id', dataType: 'INT', value: this.state.arajanlat_tulajdonos_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_statusz'] = {fieldName: 'arajanlat_statusz', dataType: 'INT', value: this.state.arajanlat_statusz, mandatory: false, min: null, max: null};
        this.validators['arajanlat_hivatkozas_url'] = {fieldName: 'arajanlat_hivatkozas_url', dataType: 'VARCHAR', value: this.state.arajanlat_hivatkozas_url, mandatory: false, min: null, max: null};
        this.validators['arajanlat_szallitasi_mod_id'] = {fieldName: 'arajanlat_szallitasi_mod_id', dataType: 'INT', value: this.state.arajanlat_szallitasi_mod_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_fizetesi_feltetelek'] = {fieldName: 'arajanlat_fizetesi_feltetelek', dataType: 'VARCHAR', value: this.state.arajanlat_fizetesi_feltetelek, mandatory: false, min: null, max: null};
        this.validators['arajanlat_szulo_id'] = {fieldName: 'arajanlat_szulo_id', dataType: 'INT', value: this.state.arajanlat_szulo_id, mandatory: false, min: null, max: null};
        this.validators['arajanlat_legutolso'] = {fieldName: 'arajanlat_legutolso', dataType: 'INT', value: this.state.arajanlat_legutolso, mandatory: false, min: null, max: null};
        this.validators['arajanlat_MTO'] = {fieldName: 'arajanlat_MTO', dataType: 'MTO', value: this.state.arajanlat_MTO, mandatory: false, min: null, max: null};
        this.validators['arajanlat_MTO'] = {fieldName: 'arajanlat_MTO', dataType: 'MTO', value: this.state.arajanlat_MTO, mandatory: false, min: null, max: null};

        this.validators['arajanlat_promon'] = {fieldName: 'arajanlat_promon', dataType: 'TINYINT', value: this.state.arajanlat_promon, mandatory: false, min: null, max: null};


        this.error = false;
        this.msg = "";

        this.osszesitoRef = React.createRef();
        this.arajanlat_statusz_from_server = 0;
        this.editable = true;

        this.load(this.props.id);

    }

    componentDidMount() {
        //this.setFocusedComponent();
    }

    setFocusedComponent = function () {

        return false;

        try {
            for (var i = 0; i < Object.keys(this.references).length; i++) {
                let first = Object.keys(this.references)[i];

                if (this.references[first].current != null && typeof this.references[first].current.focus != 'undefined') {
                    this.references[first].current.focus();
                    return false;
                }
            }
        } catch (e) {
            console.log("can not set focus on first component");
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load(this.props.id);
        }
    }

    reset = function () {

        this.setState({arajanlat_id: ''
            , arajanlat_ugyfel_id: ''
            , arajanlat_monogram: ''
            , arajanlat_statusz_kod: ''
            , arajanlat_azonosito: ''
            , arajanlat_verzio: ''

            , arajanlat_projekt_vezeto_id: ''
            , arajanlat_projekt_vezeto_ajanlatra: ''
            , arajanlat_tervezo_ajanlatra: ''
            , arajanlat_tervezo_id: ''

            , arajanlat_ugyfel_cim_id: ''
            , arajanlat_szallitasi_cim_id: ''
            , arajanlat_targy: ''
            , arajanlat_leiras: ''
            , arajanlat_arengedmeny: '0'
            , arajanlat_datum: ''
            , arajanlat_datum_ervenyes: ''
            , arajanlat_hatarido: ''
            , arajanlat_garancia: ''
            , arajanlat_muszaki_leirasok: ''
            , arajanlat_megjegyzes: ''

            , arajanlat_nem_tartalmazza: ''
            , arajanlat_ajanlat_keszito_id: ''
            , arajanlat_tulajdonos_id: ''
            , arajanlat_statusz: ''
            , arajanlat_hivatkozas_url: ''
            , arajanlat_szallitasi_mod_id: ''
            , arajanlat_fizetesi_feltetelek: ''
            , arajanlat_szulo_id: ''
            , arajanlat_legutolso: ''
            , ArajanlatTetel: []
            , MTOArajanlatTetel: []
            , saveAsNewVariant: false
            , saveAsActualized: false
            , arajanlat_promon: false
        });
    }

    ossesitoFrissitese = function () {

    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    arajanlatVerzioCsere = function (arajanlatId) {
        this.load(arajanlatId)
    }

    getForm = function () {

        var print = (this.props.theme == 'true') ? printWhite : printBlack;


        var arajanlatStatuszokEmpty = [
            <option key={'arajanlatStatuszokEmpty_0'} value="0">---------</option>,
            <option key={'arajanlatStatuszokEmpty_1'} value="1">ÁRAJÁNLAT</option>,
            <option key={'arajanlatStatuszokEmpty_2'} value="2">ELFOGADOTT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokEmpty_3'} value="3">AKTUALIZÁLT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokEmpty_4'} value="4">LEZÁRT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokEmpty_5'} value="5">MEGHIÚSULT AJÁNLAT</option>
        ];
        var arajanlatStatuszokArajanlat = [
            <option  key={'arajanlatStatuszokArajanlat_1'} value="1">ÁRAJÁNLAT</option>,
            <option  key={'arajanlatStatuszokArajanlat_2'}  value="2">ELFOGADOTT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokArajanlat_4'} value="4">LEZÁRT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokArajanlat_5'} value="5">MEGHIÚSULT AJÁNLAT</option>
        ];
        var arajanlatStatuszokElfogadott = [
            <option  key={'arajanlatStatuszokElfogadott_2'}  value="2">ELFOGADOTT AJÁNLAT</option>,
            <option  key={'arajanlatStatuszokElfogadott_3'}  value="3">AKTUALIZÁLT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokElfogadott_4'} value="4">LEZÁRT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokElfogadott_5'} value="5">MEGHIÚSULT AJÁNLAT</option>
        ];
        var arajanlatStatuszokAktualizalt = [

            <option   key={'arajanlatStatuszokAktualizalt_3'}  value="3">AKTUALIZÁLT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokElfogadott_4'} value="4">LEZÁRT AJÁNLAT</option>,
            <option key={'arajanlatStatuszokElfogadott_5'} value="5">MEGHIÚSULT AJÁNLAT</option>
        ];

        var szerkeszthetoFejadat = true;
        var arajanlatStatusz = [];
        switch (this.arajanlat_statusz_from_server + '') {

            case '1':
                arajanlatStatusz = arajanlatStatuszokArajanlat;
                break;
            case '2' :
                arajanlatStatusz = arajanlatStatuszokElfogadott;
                szerkeszthetoFejadat = false;
                break;
            case '3' :
                arajanlatStatusz = arajanlatStatuszokAktualizalt;
                szerkeszthetoFejadat = false;
                break;
            case '4' :
                arajanlatStatusz = arajanlatStatuszokArajanlat;
                break;
            case '5' :
                arajanlatStatusz = arajanlatStatuszokArajanlat;
                szerkeszthetoFejadat = false;
                break;
            default:
                arajanlatStatusz = arajanlatStatuszokEmpty;
                szerkeszthetoFejadat = false;

        }

        return <Container>
            <Row>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_azonosito')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <TextInsertField 
                        id={'arajanlat_azonosito'} 
                        value={this.state.arajanlat_azonosito}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['arajanlat_azonosito']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
        
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_ugyfel_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <AjanlatUgyfelComboHozzaadassal 
                        id={'arajanlat_ugyfel_id'} 
                        value={this.state.arajanlat_ugyfel_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/ugyfel_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_ugyfel_id']}
                        editable={szerkeszthetoFejadat}
                        theme={this.props.theme}
                        stateRef={this.state}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_ugyfel_cim_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ArjanlatUgyfelCimCombo 
                        id={'arajanlat_ugyfel_cim_id'} 
                        value={this.state.arajanlat_ugyfel_cim_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/ugyfel_cim_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_ugyfel_cim_id']}
                        editable={szerkeszthetoFejadat}
                        theme={this.props.theme}
                        ugyfel_id={this.state.arajanlat_ugyfel_id}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_kapcsolattarto_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <RemoteDataDisplayField 
                        id={'arajanlat_kapcsolattarto_id'} 
                        value={this.state.arajanlat_ugyfel_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/getKapcsolattartoName.php'}
        
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_szallitasi_cim_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ArajanlatSzallitasiCimCombo 
                        id={'arajanlat_szallitasi_cim_id'} 
                        value={this.state.arajanlat_szallitasi_cim_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/szallitasi_cim_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_szallitasi_cim_id']}
                        editable={szerkeszthetoFejadat}
                        theme={this.props.theme}
                        ugyfel_id={this.state.arajanlat_ugyfel_id}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>
                    {lang.getTitleBold('arajanlat_projekt_vezeto_id')}&nbsp;
                    <img 
                        src={print} 
                        onClick={() => {
                                        this.setState({arajanlat_projekt_vezeto_ajanlatra: (this.state.arajanlat_projekt_vezeto_ajanlatra == 0) ? 1 : 0});
                                    }}
                        />&nbsp;
                    <input type="checkbox" 
                           checked={(this.state.arajanlat_projekt_vezeto_ajanlatra == '1') ? true : false}
                           onChange={() => {
                                           this.setState({arajanlat_projekt_vezeto_ajanlatra: (this.state.arajanlat_projekt_vezeto_ajanlatra == 0) ? 1 : 0});
                                       }}
                           />
        
                    </Col>
                    <Col style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'arajanlat_projekt_vezeto_id'} 
                        value={this.state.arajanlat_projekt_vezeto_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/projekt_vezeto_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_projekt_vezeto_id']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
        
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>
                    {lang.getTitleBold('arajanlat_tervezo_id')}&nbsp;
                    <img 
                        src={print} 
                        onClick={() => {
                                        this.setState({arajanlat_tervezo_ajanlatra: (this.state.arajanlat_tervezo_ajanlatra == 0) ? 1 : 0});
                                    }}
                        />&nbsp;
                    <input type="checkbox" 
                           checked={(this.state.arajanlat_tervezo_ajanlatra == '1') ? true : false}
                           onChange={() => {
                                           this.setState({arajanlat_tervezo_ajanlatra: (this.state.arajanlat_tervezo_ajanlatra == 0) ? 1 : 0});
                                       }}
                           />
        
                    </Col>
                    <Col style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'arajanlat_tervezo_id'} 
                        value={this.state.arajanlat_tervezo_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/tervezo_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_tervezo_id']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3} >
                <Row >
                    <Col style={{textAlign:'left'}}>
                    {lang.getTitleBold('arajanlat_promon')}&nbsp;
                    <input type="checkbox" 
                           checked={(this.state.arajanlat_promon == '1') ? true : false}
                           onChange={() => {
                                           this.setState({arajanlat_promon: (this.state.arajanlat_promon == 0) ? 1 : 0});
                                       }}
                           />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_targy')}</Col>
                <Col md={12} style={{textAlign: 'left'}}>
                <TextInsertField 
                    id={'arajanlat_targy'} 
                    value={this.state.arajanlat_targy}  
                    setBack={this.setBack.bind(this)}
                    reference={this.references['arajanlat_targy']}
                    editable={this.editable}
                    theme={this.props.theme}
                    style={{width: '100%'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                <Row>
                    <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_datum_ervenyes')}</Col>
                    <Col md={12} style={{textAlign: 'left'}}>
                    <DateInsertField 
                        id={'arajanlat_datum_ervenyes'} 
                        value={this.state.arajanlat_datum_ervenyes}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['arajanlat_datum_ervenyes']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={4}>
                <Row>
                    <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_datum')}</Col>
                    <Col md={12} style={{textAlign: 'left'}}>
                    <DateInsertField 
                        id={'arajanlat_datum'} 
                        value={this.state.arajanlat_datum}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['arajanlat_datum']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={4}>
                <Row>
                    <Col md={12} style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_arengedmeny')}</Col>
                    <Col md={12} style={{textAlign: 'left'}}>
                    <TextInsertField 
                        id={'arajanlat_arengedmeny'} 
                        value={this.state.arajanlat_arengedmeny}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['arajanlat_arengedmeny']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_statusz')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <select
                        id={'arajanlat_statusz'} 
                        value={this.state.arajanlat_statusz}  
                        onChange={(e) => {
                                        this.setBack('arajanlat_statusz', e.target.value)
                                    }}
                        >
                        {arajanlatStatusz}
                    </select>
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_hivatkozas_url')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <TextInsertField 
                        id={'arajanlat_hivatkozas_url'} 
                        value={this.state.arajanlat_hivatkozas_url}  
                        setBack={this.setBack.bind(this)}
                        reference={this.references['arajanlat_hivatkozas_url']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_ajanlat_keszito_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'arajanlat_ajanlat_keszito_id'} 
                        value={this.state.arajanlat_ajanlat_keszito_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/ajanlat_keszito_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_ajanlat_keszito_id']}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
        
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_szallitasi_mod_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'arajanlat_szallitasi_mod_id'} 
                        value={this.state.arajanlat_szallitasi_mod_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/szallitasi_mod_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_szallitasi_mod_id']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Row>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_verzio')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ArajanlatVerzioCombo 
                        id={'arajanlat_verzio'} 
                        value={this.state.arajanlat_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/arajanlatVerzioComboData.php'}
                        reference={this.references['arajanlat_verzio']}
                        editable={true}
                        eredeti_arajanlat_id={this.state.arajanlat_eredeti_arajanlat_id}
                        arajanlatVerzioCsere={this.arajanlatVerzioCsere.bind(this)}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_monogram')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <RemoteDataDisplayField 
                        id={'arajanlat_monogram'} 
                        value={this.state.arajanlat_ugyfel_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/getUgyfelMonogram.php'}
                        editable={false}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
                <Col md={3}>
                <Row>
                    <Col style={{textAlign: 'left'}}>{lang.getTitleBold('arajanlat_tulajdonos_id')}</Col>
                    <Col style={{textAlign: 'left'}}>
                    <ComboBoxInsertField 
                        id={'arajanlat_tulajdonos_id'} 
                        value={this.state.arajanlat_tulajdonos_id}  
                        setBack={this.setBack.bind(this)}
                        dataSourceUrl={'modules/Arajanlat/ajanlat_keszito_idFormComboInsertData.php'}
                        reference={this.references['arajanlat_tulajdonos_id']}
                        editable={this.editable}
                        theme={this.props.theme}
                        />
                    </Col>
                </Row>
                </Col>
            </Row>
            <Tabs defaultActiveKey="arajanlat_leiras" id="tab_hatarido" className="mb-3">
                <Tab eventKey="arajanlat_leiras" title={lang.getTitleBold('arajanlat_leiras')} >
                    <Row>
                        <Col md={12}  style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_leiras'} 
                            kategoria_konstans={'LEIRAS'}
                            value={this.state.arajanlat_leiras}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_leiras']}
                            editable={this.editable}   
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="arajanlat_hatarido" title={lang.getTitleBold('arajanlat_hatarido')}>
                    <Row>
                        <Col md={12} style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_hatarido'} 
                            kategoria_konstans={'HATARIDO'}
                            value={this.state.arajanlat_hatarido}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_hatarido']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="arajanlat_fizetesi_feltetelek" title={lang.getTitleBold('arajanlat_fizetesi_feltetelek')} >
                    <Row>
                        <Col md={12}  style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_fizetesi_feltetelek'} 
                            kategoria_konstans={'FIZETESI_FELTETELEK'}
                            value={this.state.arajanlat_fizetesi_feltetelek}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_fizetesi_feltetelek']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="arajanlat_garancia" title={lang.getTitleBold('arajanlat_garancia')} >
                    <Row>
                        <Col md={12}  style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_garancia'} 
                            kategoria_konstans={'GARANCIA'}
                            value={this.state.arajanlat_garancia}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_garancia']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
        
                <Tab eventKey="arajanlat_muszaki_leirasok" title={lang.getTitleBold('arajanlat_muszaki_leirasok')} >
                    <Row>
                        <Col md={12} style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_muszaki_leirasok'} 
                            kategoria_konstans={'MUSZAKI_LEIRAS'}
                            value={this.state.arajanlat_muszaki_leirasok}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_muszaki_leirasok']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="arajanlat_megjegyzes" title={lang.getTitleBold('arajanlat_megjegyzes')} >
                    <Row>
                        <Col md={12}  style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_megjegyzes'} 
                            kategoria_konstans={'MEGJEGYZES'}
                            value={this.state.arajanlat_megjegyzes}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_megjegyzes']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
                <Tab eventKey="arajanlat_nem_tartalmazza" title={lang.getTitleBold('arajanlat_nem_tartalmazza')} >
                    <Row>
                        <Col md={12}  style={{textAlign: 'left'}}>
                        <TextFormatter 
                            sablonKivalasztoval={true}
                            id={'arajanlat_nem_tartalmazza'} 
                            kategoria_konstans={'NEM_TARTALMAZZA'}
                            value={this.state.arajanlat_nem_tartalmazza}  
                            setBack={this.setBack.bind(this)}
                            reference={this.references['arajanlat_nem_tartalmazza']}
                            editable={this.editable}
                            theme={this.props.theme}
                            />
                        </Col>
                    </Row>
                </Tab>
        
            </Tabs>
            <Row>
                <Col style={{minHeight: 150}}>
                &nbsp;
                </Col>
            </Row>
        
        </Container>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = lang.getTitleBold('transaction_success');
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = lang.getTitleBold(errorStringConstant);
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    update = function (e) {
        var validator = ajax.Validator();
        var validForm = validator.formValidator(this.references, this.validators, this.state);
        if (validForm == false) {
            return false;
        }

        var self = this;
        var method = e.target.id;
        console.log(e.target);
        if (method == "newVariant") {
            self.state.saveAsNewVariant = true;
        } else {
            self.state.saveAsNewVariant = false;
        }

        if (method == "actualized") {
            self.state.saveAsActualized = true;
        } else {
            self.state.saveAsActualized = false;
        }

        this.concatAdditionalParams();

        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatFormUpdate.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(self.state)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            self.transactionSuccess();
                            var aktiv_arajanlat_id = (typeof response.data.uj_ajanlat_id != 'undefined') ? response.data.uj_ajanlat_id : self.state.arajanlat_id;

                            this.load(aktiv_arajanlat_id);
                            if (method == 'print') {
                                // self.openPDF();
                            } else {
                                if (self.state.saveAsNewVariant != true && self.state.saveAsActualized != true) {
                                    //    self.closeForm();
                                }
                            }
                        } else {
                            self.transactionFail(response.fail);
                        }
                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    load = function (id) {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/Arajanlat/arajanlatFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            var arajanlat_statusz = response.data[0].arajanlat_statusz;
                            this.editable = arajanlat_statusz != 2;
                            //this.editable = true;
                            this.arajanlat_statusz_from_server = arajanlat_statusz;
                            self.setState(response.data[0]);


                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {
                        self.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.defaultUpdateBlocked = false;
                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.defaultUpdateBlocked = false;
                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.defaultUpdateBlocked = false;
            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    forceReload = function () {
        this.osszesitoRef.current.forceUpdate();
        if (typeof this.osszesitoRef != 'undefined') {
            return false;
        }
        if (this.osszesitoRef != null) {
            return false;
        }
        if (typeof this.osszesitoRef.current != 'undefined') {
            return false;
        }
        if (this.osszesitoRef.current != null) {
            return false;
        }
        if (typeof this.osszesitoRef.current.forceUpdate != 'undefined') {
            return false;
        }
        if (this.osszesitoRef.current.forceUpdate != null) {
            return false;
        }
        this.osszesitoRef.current.forceUpdate();
    }

    udpateAdditionalParams = function () {
        if (typeof this.props.additionalParams == 'undefined') {
            return false;
        }
        if (this.props.additionalParams == null) {
            return false;
        }
        if (this.props.additionalParams.length == 0) {
            return false;
        }

        for (var i = 0; i < this.props.additionalParams.length; i++) {
            if (typeof this.props.additionalParams[0].arajanlatTetelek_arajanlat_id != 'undefined') {
                if (this.props.additionalParams[0].arajanlatTetelek_arajanlat_id != null) {
                    if (this.props.additionalParams[0].arajanlatTetelek_arajanlat_id != this.state.arajanlat_id) {
                        this.props.additionalParams[0].arajanlatTetelek_arajanlat_id = this.state.arajanlat_id;
                    }
                }
            }
        }

    }

    openAktualizaltSzovegbox = function () {
        window.open('./?menu=AktualizaltSzovegbox&id=' + this.state.arajanlat_id, '_blank');
    }

    googleDriveUpload = function () {
        // window.open( ajax.getUrlBase() + './google_drive/googleDriveFileUpload.php?type=arajanlat&id='+this.state.arajanlat_id,'_blank');
        window.open(ajax.getUrlBase() + "ExportToFile/arajanlat/index.php?state=AF_" + this.state.arajanlat_id, '_blank');
    }

    getTabbedForm = function () {
        var form = this.getForm();
        // Amikor a formon átállítják a verziót az additional parms-ban is 
        // frissíteni kell az arajanlatTetelek_arajanlat_id paraméter értékét 
        // a kiválasztott ajánlatnak megfelelően
        this.udpateAdditionalParams();


        if (this.state.arajanlat_statusz == '3') {
        }



        return <Tabs defaultActiveKey="ajanlat" id="tab_insert" className="mb-3">
            <Tab eventKey="ajanlat" title={lang.getTitleBold('Arajanlat')}>
                {form}
            </Tab>
            <Tab eventKey="ajanlat_tetel" title={lang.getTitleBold('ArajanlatTetel')} >
        
        
                <ArajanlatTetelContent   
                    value={this.state.ArajanlatTetel} 
                    setBack={this.setBack.bind(this)} 
                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                    editable={this.editable}
                    formType={(typeof this.props.formType == 'undefined') ? null : (this.editable == false) ? 'VIEW' : this.props.formType}
                    theme={this.props.theme}
                    arajanlat_arengedmeny={this.state.arajanlat_arengedmeny}
                    forceReload={this.forceReload.bind(this)}
                    />
        
        
            </Tab>
        
        </Tabs>
    }

    openPDF = function (e) {
        var mod = e.target.id;
        window.open(ajax.getUrlBase() + "ExportToFile/arajanlat/index.php?action=print&id=" + this.state.arajanlat_id + "&mod=" + mod);
    }

    veglegesit = function (e) {
        this.state.veglegesitve = true;
        this.update(e);
    }

    render() {
        
        var form = this.getTabbedForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";



        var buttonUpdate = null;
        var buttonVariant = null;
        var buttonActualized = null;
        var buttonOpenActualized = null;
        var veglegesit = null;
        var buttonGoogleDrive = "";

        if (this.arajanlat_statusz_from_server == '1') {
            buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>Módosít</Button>;
            buttonVariant = <Button className={'btn-sm'} id={'newVariant'} onClick={this.update.bind(this)} variant="danger" style={{marginTop: '5px'}}>Új változat létrehozása</Button>;
            // buttonActualized = <Button className={'btn-sm'} id={'actualized'} onClick={this.actualized.bind(this)} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('actualized')}</Button>;

        }
        if (this.arajanlat_statusz_from_server == '2') {
            //buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('modify')}</Button>;
            //buttonVariant = <Button className={'btn-sm'} id={'newVariant'} onClick={this.update.bind(this)} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('newVariant')}</Button>;
            buttonActualized = <Button className={'btn-sm'} id={'actualized'} onClick={this.update.bind(this)} variant="danger" style={{marginTop: '5px'}}>Új aktualizált változat létrehozása</Button>;
        }
        if (this.arajanlat_statusz_from_server == '3') {
            buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('modify')}</Button>;
            //buttonVariant = <Button className={'btn-sm'} id={'newVariant'} onClick={this.update.bind(this)} variant="danger" style={{marginTop: '5px'}}>{lang.getTitle('newVariant')}</Button>;
            buttonOpenActualized = <Button variant="light" size={'sm'} onClick={this.openAktualizaltSzovegbox.bind(this)} >{lang.getTitle('openInNewWindow')}</Button>;
            if (this.state.veglegesitve == 0 || this.state.veglegesitve == false) {
                veglegesit = <Button id={'veglegesit'} variant="danger" size={'sm'} onClick={this.veglegesit.bind(this)} >Véglegesít</Button>;
            } else {
                buttonActualized = <Button className={'btn-sm'} id={'actualized'} onClick={this.update.bind(this)} variant="danger" style={{marginTop: '5px'}}>Aktualizál</Button>;
            }
        }

        if (this.arajanlat_statusz_from_server == '4') {
            buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('modify')}</Button>;
        }
        if (this.arajanlat_statusz_from_server == '5') {
            buttonUpdate = <Button className={'btn-sm'} id={'modify'} onClick={this.update.bind(this)} variant="success" style={{marginTop: '5px'}}>{lang.getTitle('modify')}</Button>;
        }

        buttonGoogleDrive = <img onClick={this.googleDriveUpload.bind(this)}  title={"Feltöltés a Google Drive fiókba."} style={{minWidth: 30, maxWidth: 30, cursor: 'pointer'}} src={googleDriveIcon} />;
        
        

        return (<PopUpWindow theme={this.props.theme}  style={{minWidth: 1100, minHeight: '100%'}}>
            
            <div className={'popupWrapper' + className} id="ArajanlatUpdate">
                <div  className={"popupHeader" + className} >
                    <Row>
                        <Col style={{textAlign: 'left'}}>
                        <Button id="print-an" variant="primary" size="sm" onClick={this.openPDF.bind(this)} ><img src={printWithoutMoney} />Nyomtatás</Button>&nbsp;&nbsp;&nbsp;
                        <Button id="print" variant="success" size="sm" onClick={this.openPDF.bind(this)} ><img src={printWithMoney} />Nyomtatás</Button>
                        </Col>
                        <Col style={{textAlign: 'center'}}>{lang.getTitleBold('ArajanlatUpdate')}</Col>
                        <Col style={{textAlign: 'right'}}>
                        <Button className={'btn-sm'} variant="danger" onClick={this.closeForm.bind(this)}>{lang.getTitle('close')}</Button>
                        </Col>
                    </Row>
                </div>
                <div  className={"popupBody" + className} >{form}</div>
                <div  className={"popupFooter" + className} >
                    <ArajanlatKalkulator 
                        theme={this.props.theme} 
                        stateRef={this.state}
                        ref={this.osszesitoRef}
        
                        />
                    <Row>
                        <Col>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        {veglegesit}&nbsp;&nbsp;&nbsp;{buttonActualized}&nbsp;&nbsp;&nbsp;{buttonOpenActualized}&nbsp;&nbsp;&nbsp;{buttonUpdate}&nbsp;&nbsp;&nbsp;{buttonVariant}&nbsp;&nbsp;&nbsp;{buttonGoogleDrive}
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{minHeight: '10px'}}>
                              
                        </Col>
                    </Row>
                </div>
            </div>
        </PopUpWindow>);
    }
}

export default ArajanlatUpdate;

