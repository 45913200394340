import React, { Component } from 'react';
export  function getTitle(nameConstant) {
    if ((!lang.hasOwnProperty(nameConstant)) || lang[nameConstant] === '') {
        return nameConstant;
    }
    return lang[nameConstant];
}

export  function getTitleBold(nameConstant) {
    if ((!lang.hasOwnProperty(nameConstant)) || lang[nameConstant] === '') {
        return nameConstant;
    }
    return <b>{lang[nameConstant]}</b>;
}

var lang = {
    Insert: 'Új tétel'
    , save_state: 'Állapot mentése'
    , View: 'Megtekint'
    , Edit: 'Módosít'
    , Update: 'Módosít'
    , Delete: 'Töröl'
    , Actions: 'Műveletek'
    , save: 'Mentés'
    , 'new': 'Mentés és Új'
    , confirmation_dialog: 'Megerősítés kérése'
    , Cancel: 'Mégsem'
    , Close: 'Bezárás'
    , close: 'Bezárás'
    , Submit: 'Rögzít'
    , New: 'Új'
    , transaction_success: 'Sikeres tranzakció'
    , delete_record_confirmation_text: 'Biztos benne, hogy törli az adatot?'
    , Images: 'Képek'
    , all: 'Összes'
    , yes: 'Igen'
    , no: 'Nem'
    , Files: 'Fájlok feltöltése'
    , invalid_form: 'Az űrlap nincs megfelelően kitöltve. A piros felkiáltójel melletti mezőket kötelező kitölteni.'
    , sign: 'Aláír'
    , Login: 'Bejelentkezés'
    , logOut: 'Kijelentkezés'
    , loggedInUser: 'Bejelentkezve'
    , users_password: 'Jelszó'
    , users_password_again: 'Jelszó ismétlése'
    , changePassword: 'Jelszó változtatás'
    , invalid_login_form: 'Sikertelen bejelentkezés'
    , ROW_SELECTION_NEED: 'Kérem válasszon ki egy sort a táblázatban.'
    , DELETE_CONFIRMATION: 'Biztosan törölni kívánja a tételt.'
    , ALERT_DIALOG_TITLE: 'Értesítés'
    , CONFIRMATION_DIALOG_TITLE: 'Megerősítés kérése'
    , MANDATORY_PARAMETER: 'KÖTELEZŐ KITÖLTENI'
    , MIN_VALUE: 'Minimum'
    , MAX_VALUE: 'Maximum'
    , print: 'Nyomtatás'
    , modify: 'Módosít'
    , newVariant: 'Új változat létrehozása'
    , exportClipboard: 'Vágólapra'
    , saveAndSendingEmail: 'Mentés és E-mail küldése'
    , dolgozo: 'Dolgozó'
    , felelos: 'Felelős'
    , select: 'Kiválaszt'
    , search: 'Keresés'
    , openInNewWindow: 'Dokumentáció'
    , Lekerdezes: 'Lekérdezés'
    , veglegesit: 'Véglegesít'
    , Torzsadatok: 'Törzsadatok'
    , JogosultsagTipusTorlese: 'Jogosultság típus törlése'
    , Jogosultsagok: 'Jogosultságok'
    , Meghiusult: 'Meghiúsult'
    , Lezart: 'Lezárt'
    , filter: 'Szűrés'
    , reset_filter: 'Filterek törlése'
    , MobileFilter: 'Mobil szűrés'

    , Arajanlat: 'Arajanlat'
    
    , arajanlat_id: '#'
    , arajanlat_ugyfel_id: 'Ügyfél'
    , arajanlat_monogram: 'Monogram'
    , arajanlat_statusz_kod: 'Státusz kód'
    , arajanlat_azonosito: 'Ajánlat azonosító'
    , arajanlat_verzio: 'Verzió'
    , arajanlatTetelek_eredeti_netto_ar: 'Eredeti nettó ár'
    , usersAsTulajdonos_name: 'Tulajdonos'
    , arajanlat_kapcsolattarto_id: 'Kapcsolattartó'
    , arajanlat_projekt_vezeto_id: 'Projektvezető'
    , arajanlat_projekt_vezeto_ajanlatra: 'Kapcsolati adatok'
    , arajanlat_tervezo_ajanlatra: 'Kapcsolati adatok'
    , arajanlat_tervezo_id: 'Tervező'
    , arajanlat_csoportvezeto_id: 'Csoportvezető'
    , arajanlat_ugyfel_cim_id: 'Ügyfél cím'
    , arajanlat_szallitasi_cim_id: 'Szállítási cím'
    , arajanlat_targy: 'Tárgy'
    , arajanlat_leiras: 'Leírás'
    , arajanlat_arengedmeny: 'Árengedmény'
    , arajanlat_datum: 'Határidő (Belső adat)'
    , arajanlat_datum_ervenyes: 'Érvényes'
    , arajanlat_hatarido: 'Határidő'
    , arajanlat_garancia: 'Garancia'
    , arajanlat_muszaki_leirasok: 'Műszaki leírás'
    , arajanlat_megjegyzes: 'Megjegyzés'
    , arajanlat_aktualizalasMegjegyzes: 'Aktualizálás'
    , arajanlat_ajanlat_keszito_id: 'Készítette'
    , arajanlat_tulajdonos_id: 'Tulajdonos'
    , arajanlat_statusz: 'Státusz'
    , arajanlat_hivatkozas_url: 'Hivatkozás URL (Belső adat)'
    , arajanlat_szallitasi_mod_id: 'Szállítási mód'
    , arajanlat_nem_tartalmazza: 'Nem tartalmazza'
    , arajanlat_fizetesi_feltetelek: 'Fizetési feltételek'
    , arajanlat_szulo_id: 'arajanlat_szulo_id'
    , arajanlat_legutolso: 'arajanlat_legutolso'
    , arajanlat_projektvezeto_nyomatatasban: 'Nyomtatásra'
    , arajanlat_tervezo_nyomatatasban: 'Nyomtatásra'
    , actualized: 'Új aktualizált ajánlat létrehozása'
    , tetelekOsszesen: 'Kedvezménnyel(Ft)'
    , tetelekOsszege: 'Tétel össz(Ft)'
    , tetelekKedvezmenyMerteke: 'Kedvezmény'
    , Sorszam: 'Sorszám'
    , arajanlat_createdAt: 'Létrehozva'
    , arajanlat_modifiedAt: 'Módosítva'
    , ArajanlatHistoryForm:'Változásnapló'
    

    , opcionalisTetelekOsszege: 'Opc. tételek(Ft)'
    , opcionalis_tetelek_osszesen: 'Opc. tételek nettó'

    , vegosszesen: 'Végösszesen(Ft)'

    , ugyfelId: 'Ügyfél'
    , ugyfelek_id: '#'
    , ugyfelek_nev: 'Ügyfél neve'
    , ugyfelek_adoszam: 'Adószám'
    , ugyfelek_eu_adoszam: 'EU adószám'
    , ugyfelek_monogram: 'Monogram'
    , ugyfelek_cegjegyzek_szam: 'Cégjegyzék szám'
    , ugyfelek_kapcsolattarto_id: 'Kapcsolattartó'
    , ugyfelek_kapcsolattarto_nev: 'Kapcsolattartó'

    , ugyfelek_telefon: 'Telefon'
    , ugyfelek_email: 'E-mail'
    , ugyfelek_web: 'Web'
    , ugyfelek_created_at: 'Készült'
    , ugyfelek_megjegyzes: 'Megjegyzés'

    , Kapcsolattartok: '#'
    , KapcsolattartokInsert: 'Kapcsolattartó felvitele'
    , KapcsolattartokUpdate: 'Kapcsolattartó módosítása'
    , KapcsolattartokDelete: 'Kapcsolattartó törlése'
    , kapcsolattartok_id: '#'
    , kapcsolattartok_nev: 'Kapcsolattartó neve'
    , kapcsolattartok_telefon: 'Telefon'
    , kapcsolattartok_email: 'E-mail'
    , kapcsolattartok_web: 'Web'
    , kapcsolattartok_megjegyzes: 'Megjegyzés'
    , kapcsolattartok_aktiv: 'Aktív'

    , usersAsCsoportvezeto_id: 'Csoportvezető'
    , usersAsCsoportvezeto_name: 'Csoportvezető neve'

    , usersAsProjektVezeto_id: '#'
    , usersAsProjektVezeto_name: 'Projektvezető neve'

    , usersAsTervezo_id: 'Tervező'
    , usersAsTervezo_name: 'Tervező neve'

    , ugyfelCimek_cim: 'Ügyfél cím'
    , usersAsAjanlatKeszito_id: 'Ajánla készítő'
    , usersAsAjanlatKeszito_name: 'Ajánlat készítő neve'
    , arajanlatTetelSzallitasiMod_id: 'Szállítási mód'
    , arajanlatTetelSzallitasiMod_megnevezes: 'Megnevezés'
    , arajanlat_MTO: 'Árajánlatok'

    , ArajanlatTetel: 'Ajánlat tétel'
    , ArajanlatTetelInsert: 'Ajánlat tétel felvitel'
    , ArajanlatTetelUpdate: 'Ajánlat tétel módosítása'
    , ArajanlatTetelView: 'Ajánlat tétel megtekintése'
    , ArajanlatTetelUpdate: 'Ajánlat tétel módosítás'
    , ArajanlatTetelInsert: 'Ajánlat tétel felvitel'
    , arajanlatTetelek_MTO: 'Ajánlat tételek'
    , arajanlatTetelek_id: '#'
    , arajanlatTetelek_manualis_tetel_sorszam: 'Sorszám (Manuális)'
    , arajanlatTetelek_arajanlat_id: 'Árajánlat'
    , arajanlatTetelek_megnevezes: 'Megnevezés'
    , arajanlatTetelek_hivatkozas_megjegyzes: 'Hiv. megjegyzés'
    , arajanlatTetelek_hivatkozas: 'Hivatkozás'
    , arajanlatTetelek_netto_ar: 'Nettó'
    , arajanlatTetelek_darabszam: 'Darabszám'
    , arajanlatTetelek_mennyisegiEgyseg: 'Me.:'
    , arajanlatTetelek_nettoOsszesen: 'Tétel ára összesen'
    , arajanlatTetelek_eredeti_netto_ar: 'Eredeti'
    , arajanlatTetelek_szallitasi_mod_id: 'Szállítási mód'
    , arajanlatTetelek_arengedmeny_szazalek: 'Árengedmény'
    , arajanlatTetelek_arengedmeny_ft: 'Engedménnyel csökkentett ár'
    , arajanlatTetelek_tetel_kibontas: 'Tétel kibontás'

    , MTOArajanlatTetel: 'Árajánlat tétel hozzáadása'
    , MTOArajanlatTetelInsert: 'Árajánlat tétel hozzáadása'
    , MTOArajanlatTetelUpdate: 'Árajánlat tétel Módosítása'

    , Arajanlat: 'Árajánlat'
    , ArajanlatUpdate: 'Árajánlat módosítás'
    , ArajanlatView: 'Árajánlat megtekintése'
    , ArajanlatInsert: 'Árajánlat felvitel'

    , ArajanlatTetelOpciok: 'Opcionális tételek'
    , ArajanlatTetelOpciokInsert: 'Opcionális tételek felvitele'
    , ArajanlatTetelOpciokUpdate: 'Opcionális tételek módosítása'
    , ArajanlatTetelOpciokView: 'Opcionális tételek megtekintése'
    , arajanlatTetelOpciok_id: '#'
    , arajanlatTetelOpciok_manualis_sorszam: 'Sorszám (Manuális)'
    , arajanlatTetelOpciok_arajanlat_tetel_id: '#'
    , arajanlatTetelOpciok_megnevezes: 'Opcionalis tétel leírás'
    , arajanlatTetelOpciok_hivatkozas: 'Hivatkozás URL'
    , arajanlatTetelOpciok_netto_ar: 'Nettó ár(Ft)'
    , arajanlatTetelOpciok_darabszam: 'Darabszám'
    , arajanlatTetelOpciok_nettoOsszesen: 'Opcionális tétel ára összesen'
    , arajanlatTetelOpciok_megjegyzes: 'Megjegyzés'
    , arajanlat_szulo_id: "Szülő"
    , arajanlat_legutolso: "Utolsó"

    , MTOArajanlatTetelOpciok: 'Opcionális tételek'
    , MTOArajanlatTetelOpciokInsert: 'Opcionális tételek felvitele'
    , MTOArajanlatTetelOpciokUpdate: 'Opcionális tételek módosítása'


    , Cikkcsoportok: 'Cikkcsoportok'
    , CikkcsoportokInsert: 'Cikkcsoportok felvitele'
    , CikkcsoportokUpdate: 'Cikkcsoportok módosítása'
    , CikkcsoportokView: 'Cikkcsoportok megtekintése'
    , cikkcsoportok_id: '#'
    , cikkcsoportok_megnevezes: 'Cikkcsoport'
    , cikkcsoportok_user_id: 'Felhasználó'
    , cikkcsoportok_megjegyzes_csoport: 'Megjegyzés'

    , CikkcsoportokFelhasznalok: 'Cikkcsoportom'
    , cikkcsoportokFelhasznalok_id: '#'
    , cikkcsoportokFelhasznalok_cikkcsoport_id: 'Cikkcsoport'
    , cikkcsoportokFelhasznalok_user_id: 'Felhasználó'
    , CikkcsoportokFelhasznalokInsert: 'Cikkcsoport Felhasználó Összerendelés'

    , Felhasznalok: 'Felhasználók'
    , FelhasznalokInsert: 'Felhasználók felvitele'
    , FelhasznalokUpdate: 'Felhasználók módosítása'
    , FelhasznalokView: 'Felhasználók megtekintése'
    , users_id: '#'
    , users_name: 'Név'
    , users_display_name: 'Rövid név'
    , users_email: 'E-mail'
    , users_email_verified_at: 'Jóváhagyás időpontja'
    , users_password: 'Jelszó'
    , users_remember_token: 'Emlékeztető token'
    , users_created_at: 'Készült'
    , users_updated_at: 'Módosítva'
    , users_user_type: 'Felhasználó típusa'
    , users_telefon: 'Telefonszám'
    , users_irsz: 'IRSZ'
    , users_telepules: 'Település'
    , users_cim: 'Cím'
    , users_megjegyzes: 'Megjegyzés'
    , users_aktiv: 'Gépjárműhöz rend.' // Ez lett végül a gépjárműhöz rendelhető
    , users_szuneteltetve: 'Szüneteltetve' // Ez lett végül a gépjárműhöz rendelhető
    , users_belepes_datum: 'Belépés dátum'
    , users_szul_hely: 'Születési hely'
    , users_szul_ido: 'Születési idő'
    , users_anyja_neve: 'Anyja neve'
    , users_taj_szam: 'TAJ szám'
    , users_adoazonosito: 'Adóazonosító'
    , users_kulsos_munkavallalo: 'Külsős munkavállaló'
    , users_projekt_vezeto: 'Projekvezető'
    , users_tervezo: 'Tervező'
    , users_munkalaphoz_rendelheto: 'Munkalaphoz rendelhető'
    , users_minden_ajanlatot_szerkeszthet: 'Minden ajánlatot szerkeszthet'

    , Kapcsolattartok: 'Kapcsolattartók'


    , Ugyfelek: 'Ügyfelek'
    , Rendelesek: 'Rendelések'
    , rendelesek: 'Rendelések'
    , FestekRendelesek: 'Festék rendelések'
    , FestekRendelesekInsert: 'Festék rendelések felvitele'
    , FestekRendelesekUpdate: 'Festék rendelések módosítása'
    , FestekRendelesekView: 'Festék rendelések megtekintése'
    , festekRendelesek_id: '#'
    , festekRendelesek_datum: 'Dátum'
    , festekRendelesek_email_cim: 'E-mail'
    , festekRendelesek_email_forward: 'E-mail továbbítva'
    , festekRendelesek_cikkek: 'Cikkek'
    , festekRendelesek_elkuldve: 'Elküldve'

    , autok_id: '#'
    , autok_rendszam: 'Rendszám'
    , autok_tipus: 'Típus'
    , autok_muszaki_ervenyes: 'Műszaki érvényesség'
    , autok_szemelyek_szama: 'Személyek száma'
    , autok_elerheto: 'Elérhető'
    , autok_aktiv: 'Aktív'
    , autok: 'Járművek'
    , Autok: 'Járművek'
    , AutokUpdate: 'Jármű módosítása'
    , AutokInsert: 'Jármű felvitele'
    , AutokView: 'Jármű megtekintése'
    , autok_megjegyzes: 'Megjegyzés'
    , autok_megjegyzesek: 'Rögzített megjegyzések'

    , SzovegSablonok: 'Szövegsablonok'
    , SzovegSablonokInsert: 'Szöveg sablon felvitel'
    , SzovegSablonokUpdate: 'Szöveg sablon módosítás'
    , SzovegSablonokView: 'Szöveg sablon megtekintés'

    , szovegSablonok_id: '#'
    , szovegSablonok_kategoria_konstans: 'Kategória'
    , szovegSablonok_nev: 'Név'
    , szovegSablonok_szoveg: 'Szöveg'

    , HATARIDO: 'Határidő'
    , GARANCIA: 'Garancia'
    , LEIRAS: 'Leírás'
    , MUSZAKI_LEIRAS: 'Műszaki leírás'
    , MEGJEGYZES: 'Megjegyzés'
    , FIZETESI_FELTETELEK: 'Fizetési feltételek'
    , NEM_TARTALMAZZA: 'Nem tartalmazza'



    , UgyfelCimekKarbantartas: 'Ügyfél címek'
    , UgyfelCimekKarbantartasInsert: 'Ügyfél felvitele'
    , UgyfelCimekKarbantartasUpdate: 'Ügyfél módosítása'
    , UgyfelCimekKarbantartasView: 'Ügyfél megtekintése'
    , ugyfelCimek_id: '#'
    , ugyfelCimek_ugyfel_id: 'Ügyfél'
    , ugyfelCimek_elsodleges: 'Elsődleges cím'
    , ugyfelCimek_irsz: 'IRSZ'
    , ugyfelCimek_telepules: 'Település'
    , ugyfelCimek_telepulesNev: 'Település'
    , ugyfelCimek_cim: 'Cím'
    , ugyfelCimek_cim_tipus: 'Cím elnevezés'
    , ugyfelekcimkereso: 'Címe'


    , MTOUgyfelCimekKarbantartas: 'Ügyfél címek'
    , MTOUgyfelCimekKarbantartasInsert: 'Ügyfél címek felvitele'
    , MTOUgyfelCimekKarbantartasUpdate: 'Ügyfél címek módosítása'
    , MTOUgyfelCimekKarbantartasView: 'Ügyfél címek Megtekintése'

    , UgyfelekKarbantartas: 'Ügyféltörzs'
    , UgyfelekKarbantartasInsert: 'Ügyfél felvitele'
    , UgyfelekKarbantartasUpdate: 'Ügyfél módosítása'
    , UgyfelekKarbantartasView: 'Ügyfél megtekintése'
    , UgyfelekKarbantartasContent: 'Ügyféltörzs'
    , UgyfelekKarbantartasFilter: 'Szűrés'



    , MunkaBeosztas: 'Munkabeosztás lista'
    , MunkaBeosztasNaptar: 'Munkabeosztás naptár'
    , MunkaBeosztasInsert: 'Munkabeosztás felvitele'
    , MunkaBeosztasUpdate: 'Munkabeosztás módosítása'
    , MunkaBeosztasView: 'Munkabeosztás megtekintése'
    , munkaBeosztas_id: '#'
    , munkaBeosztas_datum: 'Dátum'
    , munkaBeosztas_auto_id: 'Jármű'
    , munkaBeosztas_ugyfel_id: 'Ügyfél'
    , munkaBeosztas_ugyfel_cim_id: 'Ügyfél címe'
    , munkaBeosztas_megjegyzes: 'Megjegyzés'
    , munkaBeosztas: 'Munkabeosztás'
    , munkavallalok_users_name: 'Munkavállalók'

    , munkabeosztasNaptarBefoglalas_datum: "Dátum"
    , munkabeosztasNaptarBefoglalas_megjegyzes: "Megjegyzés"


    , ugyfelASmunkabeosztasUgyfel_id: 'Ügyfél '
    , ugyfelASmunkabeosztasUgyfel_nev: 'Ügyfél neve'
    , ugyfelASmunkabeosztasUgyfel_adoszam: 'Adószám'
    , ugyfelASmunkabeosztasUgyfel_eu_adoszam: 'EU adószám'
    , ugyfelASmunkabeosztasUgyfel_cegjegyzek_szam: 'Cégjegyzékszám'
    , ugyfelASmunkabeosztasUgyfel_kapcsolattarto_id: 'Kapcsolattartó'
    , ugyfelASmunkabeosztasUgyfel_telefon: 'Telefon'
    , ugyfelASmunkabeosztasUgyfel_email: 'E-mail'
    , ugyfelASmunkabeosztasUgyfel_web: 'WEB'
    , ugyfelASmunkabeosztasUgyfel_created_at: 'Készült'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_id: 'Kapcsolattartó'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_nev: 'Kapcsolattartó név'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_telefon: 'Kapcsolattartó telefon'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_email: 'Kapcsolattartó E-mail'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_web: 'Kapcsolattartó web'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_megjegyzes: 'Kapcsolattartó megjegyzés'
    , kapcsolattartoASmunkabeosztasUgyfelKapcsolattarto_aktiv: 'Kapcsolattartó aktív'

    , munkaBeosztas_MTO: 'munkaBeosztas_MTO'


    , MunkaBeosztasCimek: 'Címek'
    , MunkaBeosztasCimekInsert: 'Cím felvitele'
    , MunkaBeosztasCimekUpdate: 'Cím módosítása'
    , MunkaBeosztasCimekView: 'Cím megtekintése'

    , munkaBeosztasCimek_id: '#'
    , munkaBeosztasCimek_munka_beosztas_id: 'Beosztás'
    , munkaBeosztasCimek_ugyfel_id: 'Ügyfél (Nem kötelező)'
    , munkaBeosztasCimek_irsz: 'IRSZ'
    , munkaBeosztasCimek_telepules: 'Település'
    , munkaBeosztasCimek_cim: 'Cím'
    , munkaBeosztasCimek_megjegyzes: 'Megjegyzés'
    , munkaBeosztasCimek_telepulesNev: 'Település'
    , munkaBeosztasCimek_ugyfel_nev: 'Ügyfél'

    , MTOMunkaBeosztasCimek: 'Címek'
    , MTOMunkaBeosztasCimekInsert: 'Címek felvitele'
    , MTOMunkaBeosztasCimekUpdate: 'Címek módosítása'
    , MTOMunkaBeosztasCimekView: 'Címek megtekintése'


    , MunkaBeosztasDolgozok: 'Dolgozók'
    , MunkaBeosztasDolgozokInsert: 'Dolgozók felvitele'
    , MunkaBeosztasDolgozokUpdate: 'Dolgozók módosítása'
    , MunkaBeosztasDolgozokView: 'Dolgozók megtekintése'
    , munkaBeosztasDolgozok_id: '#'
    , munkaBeosztasDolgozok_munka_beosztas_id: 'Beosztás'
    , munkaBeosztasDolgozok_user_id: 'Felhasználó'
    , munkaBeosztasDolgozok_felelos: 'Felelős'



    , MTOMunkaBeosztasDolgozok: 'Dolgozók'
    , MTOMunkaBeosztasDolgozokInsert: 'Dolgozók felvitele'
    , MTOMunkaBeosztasDolgozokUpdate: 'Dolgozók módosítása'
    , MTOMunkaBeosztasDolgozokView: 'Dolgozók megtekintése'

    , MunkaBeosztasUgyfelek: 'Ügyfelek'
    , MunkaBeosztasUgyfelekInsert: 'Ügyfelek felvitele'
    , MunkaBeosztasUgyfelekUpdate: 'Ügyfelek módosítása'
    , MunkaBeosztasUgyfelekView: 'Ügyfelek törlése'
    , munkaBeosztasUgyfelek_id: 'Ügyfelek'
    , munkaBeosztasUgyfelek_munka_beosztas_id: 'Beosztás'
    , munkaBeosztasUgyfelek_ugyfel_id: 'Ügyfél'

    , MTOMunkaBeosztasUgyfelek: 'Ügyfelek'
    , MTOMunkaBeosztasUgyfelekInsert: 'Ügyfelek felvitele'
    , MTOMunkaBeosztasUgyfelekUpdate: 'Ügyfelek módosítása'
    , MTOMunkaBeosztasUgyfelekView: 'Ügyfelek törlése'


    , Iranyitoszamok: 'Irányítószámok'
    , IranyitoszamokInsert: 'Irányítószámok felvitele'
    , IranyitoszamokUpdate: 'Irányítószámok módosítása'
    , IranyitoszamokView: 'Irányítószámok megtekintése'

    , irszam_id: '#'
    , irszam_irsz: 'IRSZ'
    , irszam_nev: 'Település neve'


    , BelsoRendelesek: 'Belső rendelések'
    , BelsoRendelesekInsert: 'Belső rendelések'
    , BelsoRendelesekUpdate: 'Belső rendelések'
    , BelsoRendelesekView: 'Belső rendelések'
    , belsoRendelesek_id: '#'
    , belsoRendelesek_statusz: 'Státusz'
    , belsoRendelesek_arajanlat_id: 'Árajánlat'
    , belsoRendelesek_munkalap_id: 'Munkalap'
    , belsoRendelesek_ugyfel_id: 'Ügyfél'
    , belsoRendelesek_cikkcsoport_id: 'Cikkcsoport'
    , belsoRendelesek_cikk_nev: 'Cikk neve'
    , belsoRendelesek_cikk_cikkszam: 'Cikkszám'
    , belsoRendelesek_cikk_mennyiseg: 'Mennyiség'
    , belsoRendelesek_cikk_mennyisegi_egyseg: 'Mennyiségi egység'
    , belsoRendelesek_hivatkozasLink: 'Link'
    , belsoRendelesek_megjegyzes: 'Megjegyzés'
    , belsoRendelesek_rogzito_id: 'Rögzítette'
    , belsoRendelesek_rogzites_datum: 'Rögzítés dátum'
    , belsoRendelesek_feldolgozo_id: 'Feldolgozta'
    , belsoRendelesek_megrendeles_datum: 'Megrendelés dátum'
    , belsoRendelesek_varhato_beerkezes: 'Várható beérkezés'
    , belsoRendelesek_valos_beerkezes: 'Bérkezett'
    , belsoRendelesek_rogzitve: 'Rendelés rögzítve'
    , belsoRendelesek_altalanos_megjegyzes: 'Átvételi megjegyzés'
    , usersASerkezteto_name: 'Átvette'
    , belsoRendelesek_erkezteto_id: 'Átvette'
    , usersASfeldolgozta_name: 'Feldolgozta'

    , MultiComment: 'Tömeges átvételi megjegyzések'
    , exportXLS: 'XLS'
    , exportCSV: 'CSV'
    , exportPDF: 'PDF'
    , MultiStatusz: 'Tömeges státusz'

    , Munkalap: 'Munkalap'
    , MunkalapHistory: 'Változásnapló'
    , MunkalapInsert: 'Munkalap felvitel'
    , MunkalapUpdate: 'Munkalap módosítás'
    , MunkalapView: 'Munkalap megtekintés'
    , MunkalapMunkavallaloHozzarendelese: 'Gyártó személyek'
    , MunkalapHistoryMunkavallaloHozzarendelese: 'Gyártó személyek'
    , munkalap_datumok_varhato_befejezes: 'Várható befejezés'
    , gyarto_szemelyek: 'Gyártó személyek'


    , munkalap_id: '#'
    , munkalap_sorszam: 'Sorszám'
    , munkalap_ajanlat_id: 'Árajánlat'
    , munkalap_datum: 'Dátum'
    , munkalap_datumok_rogzitve: 'Rögzítve'
    , munkalap_datum_festes: 'Festés dátum'
    , munkalap_datum_festes_kesz: 'Festés kész'
    , munkalap_leiras: 'Leírás'
    , munkalap_szinkod: 'Színkód'
    , munkalap_megjegyzes: 'Megjegyzés'
    , munkalap_megnevezes: 'Megnevezés'
    , munkalap_lapszabasz_informaciok: 'Lapszabász információk'
    , munkalap_reszletek: 'Részletek'
    , munkalap_tervezett_osszeszereles: 'Tervezett összeszerelés'
    , munkalap_tervezett_szallitas: 'Tervezett szállítás'
    , munkalap_kiirva: 'Kiírva'
    , munkalap_festest_igenyel: 'Festést igényel'
    , munkalap_festek_megrendelve: 'Festék megrendelve'
    , munkalap_statusz: 'Státusz'
    , munkalap_projektvezeto_id: 'Projektvezető'
    , munkalap_tervezo_id: 'Tervező'
    , munkalap_ugyfel_id: 'Ügyfél neve'
    , munkalap_ugyfel: 'Ügyfél'
    , munkalap_ugyfel_cim_id: 'Ügyfél címe'


    , MunkabeosztasMunkavallaloHozzarendelese: 'Munkavállaló hozzárendelése'
    , add_selected: '+'

    , SzabadsagokInsert: 'Szabadságok felvitele'
    , SzabadsagokUpdate: 'Szabadság módosítása'
    , SzabadsagokView: 'Szabadság megtekintése'


    , Szabadsagok: 'Szabadságok'
    , szabadsagok_id: '#'
    , szabadsagok_dolgozoId: 'Dolgozó'
    , szabadsagok_nap: 'Dátum'
    , szabadsagok_nap_ig: 'Vége'
    , szabadsagok_idoTol: 'Időpont Tól'
    , szabadsagok_idoIg: 'Időpont Ig'
    , szabadsagok_megjegyzes: 'Megjegyzés'

    , Szabadsag: 'Szabadság'
    , szabadsag: 'Szabadság'

    , szabadsagok_statusz: 'Státusz'
    , szabadsagok_created: 'Készült'
    , SzabadsagNaptar: 'Szabadságok'
    , SzabadsagokNaptar: 'Szabadságok naptár'

    , ProjektTabla: 'Projekttábla'
    , ProjektDolgozokTabla: 'Dolgozók'
    , ProjektJarmuvekTabla: 'Járművek'

    , GoogleDriveFoldersDRIVE: 'DRIVE ügyfél mappák elemei'
    , GoogleDriveFoldersSAFE: 'SAFE ügyfél mappák elemei'
    , GoogleDriveSubFoldersDRIVE: 'DRIVE munkalap mappák elemei'

    , googleDriveFoldersDRIVE_id: '#'
    , googleDriveFoldersDRIVE_folderName: 'Mappa neve'
    , GoogleDriveFoldersDRIVEInsert: 'Mappa felvitele'
    , GoogleDriveFoldersDRIVEUpdate: 'Mappa módosítása'
    , GoogleDriveFoldersDRIVEView: 'Mappa megketkintése'

    , googleDriveFoldersSAFE_id: '#'
    , googleDriveFoldersSAFE_folderName: 'Mappa neve'
    , GoogleDriveFoldersSAFEInsert: 'Mappa felvitele'
    , GoogleDriveFoldersSAFEUpdate: 'Mappa módosítása'
    , GoogleDriveFoldersSAFEView: 'Mappa megketkintése'

    , googleDriveSubFoldersDRIVE_id: '#'
    , googleDriveSubFoldersDRIVE_folderName: 'Mappa neve'
    , GoogleDriveSubFoldersDRIVEInsert: 'Mappa felvitele'
    , GoogleDriveSubFoldersDRIVEUpdate: 'Mappa módosítása'
    , GoogleDriveSubFoldersDRIVEView: 'Mappa megketkintése'

    , projektvezeto_name: 'Projektvezető'
    , tervezo_name: 'Tervező'


    , contains: 'Tartalmazza'
    , endsWith: 'Végétől'
    , notContains: 'Nem tartalmazza'
    , inlist: 'Listában'
    , notinlist: 'Nincs a listában'
    , neq: 'Nem egyenlő'
    , inrange: 'Intervallumban'
    , notinrange: 'Intervallumon kívül'
    , eq: 'Egyenlő'
    , notEmpty: 'Nem üres'
    , empty: 'Üres'
    , lt: 'Kisebb'
    , lte: 'Kisebb vagy egyenlő'
    , gt: 'Nagyobb'
    , gte: 'Nagyobb vagy egyenlő'
    , before: 'Előtte'
    , beforeOrOn: 'Előtte inkluzív'
    , afterOrOn: 'Utána inkluzív'
    , after: 'Utána'
    , start: 'Kezdés'
    , end: 'Befejezés'
    , arajanlat_promon:'PROMON'

};
